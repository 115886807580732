import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, InputGroup, Button } from 'react-bootstrap';

import VideoQuestion from '../../components/Video/VideoQuestion';
import TextQuestion from './TextQuestion';

function BtnQuestion({ selectedType, questions, setQuestions }) {

    const subSelectMode = useSelector(state => state.video.subSelectMode);

    const defaultQuestionObj = {
        questionData: '',
        questionType: 'multichoice',
        questionCount: 1,
        multiChoiceCount: 2
    };

    const renderComponent = (question, idx) => {
        console.log('selectedType', selectedType);
        switch (selectedType) {
            case 'video':
                return <VideoQuestion key={idx} questions={questions} setQuestions={setQuestions} question={question} idx={idx} />;
            case 'youtube':
                return <VideoQuestion key={idx} questions={questions} setQuestions={setQuestions} question={question} idx={idx} />;
            default:
                return <TextQuestion key={idx} questions={questions} setQuestions={setQuestions} idx={idx} />;
        }
    };

    function addQuestion() {
        setQuestions([...questions, defaultQuestionObj]);
    }

    useEffect(() => {
        console.log('questions',questions)
    },[questions])

    return (
        <>
            <div id="section-question-opts">
                {
                    questions && questions.map((el, idx) => {
                        return renderComponent(el, idx);
                    })
                }
            </div>
            
            {
                subSelectMode ? 
                    null 
                    : 
                    <div className="row">
                        <div className="col-sm-12">
                            {!subSelectMode && (
                                <button id="btn-add-question-set" type="button" className="btn btn-default" onClick={addQuestion}>+</button>
                            )}
                        </div>
                    </div>
            }
        </>
    );
}

export default BtnQuestion;