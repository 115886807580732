import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Table, InputGroup, FormControl } from 'react-bootstrap';
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';

import VideoListPagination from './Video/VideoListPagination';
import VideoDetail from './Video/VideoDetail';

import '../../resources/assets/styles/sass/Video.scss';
import LoadingComponent from './_common/LoadingComponent';

import { setShowVideoDetail, setCdn, setVsCdn, setSubSelectMode } from '../modules/video';
import { setQuestionShow } from '../modules/common';

function Video({ setQuestions }) {
    const [maxPage, setMaxPage] = useState(0);
    const [vodList, setVodList] = useState([]);
    const [filteredVodList, setFilteredVodList] = useState([]);
    const [noResult, setNoResult] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [videoInfo, setVideoInfo] = useState({});
    const [search, setSearch] = useState('');

    const videoListPerPage = 5;
    const cdn = useSelector(state => state.video.cdn);
    const showVideoDetail = useSelector(state => state.video.showVideoDetail);
    const openToken = useSelector(state => state.organization.openToken);

    const dispatch = useDispatch();

    const fetchVodList = (page, searchQuery = '') => {
        const data = JSON.stringify({
            "STATE": "TEST",
            "page": page,
            "search": searchQuery
        });

        const config = {
            method: 'post',
            url: '/local/csmsai/apis/vod.php',
            headers: {
                "organization-open-token": openToken,
                "csmsai-request": searchQuery ? "getFilteredVodList" : "getUserVodList",
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                if (response.data.code == 100) {
                    setNoResult(false);
                    setMaxPage(response.data.res.maxPage);
                    if (searchQuery) {
                        setFilteredVodList(Object.values(response.data.res.list));
                    } else {
                        setVodList(Object.values(response.data.res.list));
                    }
                }
                else if (response.data.code == 201) {
                    setNoResult(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {

        const config = {
            method: 'post',
            url: '/local/csmsai/apis/quiz.php',
            headers: {
                "organization-open-token": openToken,
                "csmsai-request": "getCDN",
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then((response) => {
                if (response.data.code == 100) {
                    dispatch(setCdn(response.data.res.cdn));
                    dispatch(setVsCdn(response.data.res.VsCdn));
                }
            })
            .catch((error) => {
                console.log(error);
            });

        return (() => {
            dispatch(setShowVideoDetail(false));
            dispatch(setSubSelectMode(false));
        })
    }, [])

    useEffect(() => {
        fetchVodList(currentPage, search);
    }, [currentPage]);

    const openVideoDetail = (el) => {
        setVideoInfo({ ...el });
        dispatch(setShowVideoDetail(true));
        dispatch(setQuestionShow(true));
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchVodList(1, search);
    };

    const resetSearch = () => {
        setSearch('');
        fetchVodList(1, '');
        setFilteredVodList([]);
        setCurrentPage(1);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="videoList" style={{ width: "100%", height: "100%" }}>
            {showVideoDetail ? (
                <VideoDetail videoInfo={videoInfo} setQuestions={setQuestions} />
            ) : (
                vodList.length > 0 || filteredVodList.length > 0 ? (
                    <>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="파일명"
                                aria-label="filename"
                                aria-describedby="basic-addon1"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={handleKeyPress}
                            />
                            {filteredVodList.length === 0 ? (
                                <InputGroup.Append onClick={handleSearch}>
                                    <InputGroup.Text>
                                        <FaSearch />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            ) : (
                                <InputGroup.Append onClick={resetSearch}>
                                    <InputGroup.Text>
                                        <FaTimes />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            )}
                        </InputGroup>

                        <Table className="videoListTable concat">
                            <thead>
                                <tr>
                                    <th scope="col">동영상</th>
                                    <th scope="col">파일명</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredVodList.length === 0 && !noResult ? (
                                    vodList.map((el, idx) => (
                                        <tr key={el.id} className="videoListRow" onClick={() => openVideoDetail(el)}>
                                            <td className='videoThumbnailColumn' style={{ paddingLeft: 30, width: '30%' }}>
                                                <div className="videoThumbnail">
                                                    <img src={cdn ? `${cdn}/${el.uuid}/thumbnail/tn_1.png` : ''} alt="Video Thumbnail" />
                                                </div>
                                            </td>
                                            <td className='contentColumn'>
                                                <div className='videoTitle'>{el.filename}</div>
                                            </td>
                                            <td style={{ width: 100 }}>
                                                <div style={{ marginLeft: 35 }}><IoIosArrowForward /></div>
                                            </td>
                                        </tr>
                                    ))
                                ) : filteredVodList.length === 0 && noResult ? (
                                    <tr>
                                        <td colSpan="3" style={{ textAlign: 'center', padding: '20px 0' }}>
                                            검색 결과가 없습니다.
                                        </td>
                                    </tr>
                                ) : (
                                    filteredVodList.map((el, idx) => (
                                        <tr key={el.id} className="videoListRow" onClick={() => openVideoDetail(el)}>
                                            <td className='videoThumbnailColumn' style={{ paddingLeft: 30, width: '30%' }}>
                                                <div className="videoThumbnail">
                                                    <img src={cdn ? `${cdn}/${el.uuid}/thumbnail/tn_1.png` : ''} alt="Video Thumbnail" />
                                                </div>
                                            </td>
                                            <td className='contentColumn'>
                                                <div className='videoTitle'>{el.filename}</div>
                                            </td>
                                            <td style={{ width: 100 }}>
                                                <div style={{ marginLeft: 35 }}><IoIosArrowForward /></div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                        <VideoListPagination
                            maxPage={maxPage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                    </>
                ) : (
                    <LoadingComponent />
                )
            )}
        </div>
    );
}

export default Video;