import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Button } from 'react-bootstrap';

import { setSubSelectMode, setCurrentQuestionSub } from '../../modules/video';

function QuestionInput({ idx, questions, setQuestions, setIsSelected }) {

    const dispatch = useDispatch();  

    const currentQuestionSub = useSelector(state => state.video.currentQuestionSub);
    const subSelectMode = useSelector(state => state.video.subSelectMode);

    function formatTime(seconds) {
        let milliseconds = seconds * 1000;
    
        let date = new Date(milliseconds);
    
        let hours = String(date.getUTCHours()).padStart(2, '0');
        let minutes = String(date.getUTCMinutes()).padStart(2, '0');
        let secs = String(date.getUTCSeconds()).padStart(2, '0');
        let millis = String(date.getUTCMilliseconds()).padStart(3, '0');
    
        return `${hours}:${minutes}:${secs}.${millis}`;
    }

    async function handleSubQuestion() {

        dispatch(setSubSelectMode(false));
        console.log('currentQuestionSub',currentQuestionSub)
        
        if (currentQuestionSub.length > 0) {
            // 새로운 질문 데이터 설정
            var newQuestion = questions;
            newQuestion[idx].questionData = {
                "indexStart" : currentQuestionSub[0].id >= 10 ? currentQuestionSub[0].id : '0' + currentQuestionSub[0].id,
                "indexEnd" : currentQuestionSub[currentQuestionSub.length - 1].id >= 10 ? currentQuestionSub[currentQuestionSub.length - 1].id : '0' + currentQuestionSub[currentQuestionSub.length - 1].id,
                "timeStart" : formatTime(currentQuestionSub[0].start),
                "timeEnd" : formatTime(currentQuestionSub[currentQuestionSub.length - 1].end),
                "text" : currentQuestionSub.reduce((acc, el) => acc + el.content, '')
            };
        
            // setQuestions가 완료된 후에 다음 작업을 실행하도록 Promise를 사용
            await new Promise<void>((resolve) => {
                setQuestions([...newQuestion]);
                resolve(); // 작업 완료 후 Promise를 해결
            });
        
            // 현재 질문 하위 목록 초기화
            dispatch(setCurrentQuestionSub([]));
            dispatch(setSubSelectMode(false));
            setIsSelected(false);
        }
        else {
            setIsSelected(false);
            return;
        }

    }

    return (
        <Row id="questionInput">
            {
                (currentQuestionSub.length > 0 && subSelectMode) &&
                    <Row style={{ width: '100%', padding: "10px 0 0" }}>
                        <div className="col-1 listItemCol1">
                            <div>
                                {currentQuestionSub[0].id >= 10 ? currentQuestionSub[0].id : '0' + currentQuestionSub[0].id}
                                ~
                                {currentQuestionSub[currentQuestionSub.length - 1].id >= 10 ? currentQuestionSub[currentQuestionSub.length - 1].id : '0' + currentQuestionSub[currentQuestionSub.length - 1].id}
                            </div>
                        </div>
                        <div className="col-2 listItemCol3">
                            <div id='start-time'>
                                {formatTime(currentQuestionSub[0].start)}
                            </div>
                            <div id='end-time'>
                                {formatTime(currentQuestionSub[currentQuestionSub.length - 1].end)}
                            </div>
                        </div>
                        <div className='col-9 listItemCol8'>
                            <div style={{ lineHeight: '1.6' }}>
                                {currentQuestionSub.reduce((acc, el) => acc + el.content, '')}
                            </div>
                        </div>
                    </Row>
            }

            <div id="checkingRow">
                <div style={{ visibility: currentQuestionSub.length == 0 && subSelectMode ? 'visible' : 'hidden' }}>
                    자막의 시작과 끝을 선택 후 완료 버튼을 눌러주세요.
                </div>
                <div><Button variant="secondary" onClick={() => handleSubQuestion()}>완료</Button></div>
            </div>
    </Row>
    );
}

export default QuestionInput;