import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Row, Nav, Navbar, Dropdown, Container, Button } from 'react-bootstrap';
import webvtt from "node-webvtt";

import Player from './Player';
import SubList from '../_common/SubList';
import ChapList from './ChapList';
import { getKoLabel } from '../../utils/getKoLabel';

import LoadingComponent from '../_common/LoadingComponent';

import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack';
import { ImPlay2 } from '@react-icons/all-files/im/ImPlay2';

import '../../../resources/assets/styles/sass/AIQuiz.scss';
import WaveSurferTimeline from './WaveSurferTimeline';

import { setShowVideoDetail, setSubSelectMode, setCurrentQuestionSub, setVideoUUID, setLangCode } from '../../modules/video';
import { setQuestionShow } from '../../modules/common';

function VideoDetail({ videoInfo, setQuestions }) {

    const scrollRef = useRef();
    const wavesurfer = useRef(null);

    const [langList, setLangList] = useState([]);
    const [selectedLang, setSelectedLang] = useState('');
    const [noVtt, setNoVtt] = useState(false);
    const [parsedSub, setParsedSub] = useState([]);
    const [playTime, setPlayTime] = useState('');
    const [durationTime, setDurationTime] = useState(0);
    const [timelineTime, setTimelineTime] = useState('');
    const [scrollHeight, setScrollHeight] = useState(0);
    const [selectedIndices, setSelectedIndices] = useState([]);
    const [navMode, setNavMode] = useState('sub');

    const dispatch = useDispatch();

    const subSelectMode = useSelector(state => state.video.subSelectMode);
    const langCode = useSelector(state => state.video.langCode);
    const openToken = useSelector(state => state.organization.openToken);

    useEffect(() => {
        return (() => {
            dispatch(setVideoUUID(''))
        })
    }, [])

    useEffect(() => {
        if (videoInfo.uuid) {

            dispatch(setVideoUUID(videoInfo.uuid))

            var data = JSON.stringify({
                "uuid": videoInfo.uuid,
            });

            var config = {
                method: 'post',
                url: '/local/csmsai/apis/vod.php',
                headers: {
                    "organization-open-token": openToken,
                    "csmsai-request": "getVodSubtitleList",
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {

                    var resData = response.data;
                    if (resData.code == 100) {
                        if (resData.res.code == 100)
                            sortingSubLangList(resData.res.data.subtitle);
                    }
                    else if (resData.code == 201) {
                        setNoVtt(true);
                    }
                })
        }
    }, [videoInfo])

    function sortingSubLangList(data) {
        const updatedSubInfo = data.map(el => {

            if (el.lang.includes('auto')) {
                el.koLabel = `[자동생성] ${el.lang.replace('auto-', '')}`;
            }
            if (el.lang.includes('korean')) {
                el.koLabel = el.koLabel.replace('korean', '한국어');
            }
            if (!el.koLabel) {
                el = getKoLabel(el);
            }
            return el;
        });

        // auto가 포함된 항목과 나머지 항목을 분리
        const autoLangs = updatedSubInfo.filter(lang => lang.lang.includes('auto'));
        const otherLangs = updatedSubInfo.filter(lang => !lang.lang.includes('auto'));

        // autoLangs를 맨 앞에 두고 그 뒤에 otherLangs를 합침
        const sorted = [...autoLangs, ...otherLangs];
        setLangList(sorted);

        // autoLangs의 첫 번째 항목을 기본값으로 설정
        if (autoLangs.length > 0) {
            setSelectedLang(autoLangs[0]);
        } else if (sorted.length > 0) {
            // autoLangs가 없을 경우 첫 번째 항목을 기본값으로 설정
            setSelectedLang(sorted[0]);
        }
    }

    useEffect(() => {
        if (selectedLang) {

            var data = JSON.stringify({
                "uuid": videoInfo.uuid,
                "lang": selectedLang.lang,
            });

            var config = {
                method: 'post',
                url: '/local/csmsai/apis/vod.php',
                headers: {
                    "organization-open-token": openToken,
                    "csmsai-request": "getUserVodSubtitleList",
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {

                    if (response.data.code == 100) {

                        const webvttString = response.data.res;
                        const correctedWebvttString = webvttString.replace(/(\d{2}:\d{2}:\d{2}\.\d{3}) --> (\d{2}:\d{2}:\d{2}\.\d{3})/g, (match, p1, p2) => {
                            if (p1 === p2) {
                                // end 값을 0.01 증가시키기
                                const parts = p2.split(':');
                                let seconds = parseFloat(parts[2]);
                                seconds += 0.01;
                                parts[2] = seconds.toFixed(3).padStart(6, '0');
                                return `${p1} --> ${parts.join(':')}`;
                            }
                            return match;
                        });
                        const parsed = webvtt.parse(correctedWebvttString);
                        if (parsed.cues.length > 0) {
                            var tmpParsed = parsed.cues.map(cue => Object.assign({
                                id: Number(cue.identifier),
                                start: cue.start,
                                end: cue.end,
                                content: cue.text
                            })
                            )

                            setParsedSub(tmpParsed);
                        }
                        else {
                            setParsedSub([]);
                        }
                    }
                })
        }
    }, [selectedLang])

    function handleLanguageChange(lang) {
        const selected = langList.find(el => el.lang === lang);
        if (selected) {
            setSelectedLang(selected);
            dispatch(setLangCode(selected.lang));
        }
    }

    useEffect(() => {
        if (parsedSub.length > 0) {
            scrollRef.current.scrollTop = scrollHeight;
        }
    }, [parsedSub, scrollHeight])

    const saveSubLists = (indices) => {
        const [start, end] = indices;
        const subListsToSave = parsedSub.slice(start, end + 1);
        dispatch(setCurrentQuestionSub(subListsToSave));
    };

    useEffect(() => {
        if (selectedIndices.length === 2) {
            saveSubLists(selectedIndices);
        }
    }, [selectedIndices]);

    useEffect(() => {
        if (!subSelectMode) {
            setSelectedIndices([]);
            dispatch(setCurrentQuestionSub([]));
        }
    }, [subSelectMode])

    function handleGoBack() {
        dispatch(setShowVideoDetail(false));
        dispatch(setQuestionShow(false));
        dispatch(setSubSelectMode(false));
        setQuestions([]);
    }

    function handleEditBtn() {
        //https://csms39.moodler.kr/local/csmsmedia/apis/studioLink.php?redirect=/vod
        // const popupManager = "MediaManager";

        const url = "/local/csmsmedia/apis/studioLink.php?redirect=/video/subtitle/" + videoInfo.uuid + "/" + selectedLang.lang;
        const width = 1500;
        const height = 1000;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        console.log('window.screen', window.screen)
        window.open(url, "_blank", `width=${width}, height=${height}, top=${top}, left=${left}`);

        // const url = "/local/csmsmedia/mediaManager.php";
        // const width = 1500;
        // const height = 1000;
        // const left = (window.screen.width / 2) - (width / 2);
        // const top = (window.screen.height / 2) - (height / 2);
        // console.log('window.screen', window.screen)
        // const popup = window.open("", popupManager, `width=${width}, height=${height}, top=${top}, left=${left}`);

        // const form = document.createElement("form");
        // form.name = 'MediaManager';
        // form.method = 'post';
        // form.action = url;
        // form.target = popupManager;

        // const inputUUID = document.createElement('input');
        // inputUUID.setAttribute("type", "hidden");
        // inputUUID.setAttribute("name", "data");
        // inputUUID.setAttribute("value", videoInfo.uuid);

        // const inputType = document.createElement('input');
        // inputType.setAttribute("type", "hidden");
        // inputType.setAttribute("name", "type");
        // inputType.setAttribute("value", "subtitle");

        // form.appendChild(inputUUID);
        // form.appendChild(inputType);
        // document.body.appendChild(form);
        // form.submit();

        // document.body.removeChild(form);
    }

    function handleAIVodBtn() {

        const url = "/local/csmsai/vod/viewer.php";
        const width = 1500;
        const height = 1000;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        console.log('window.screen', window.screen)
        window.open(`${url}?uuid=${videoInfo.uuid}`, "_blank", `width=${width}, height=${height}, top=${top}, left=${left}`);

    }

    return (
        <div className="aiQuizVod">
            <Row style={{ width: '100%', padding: "10px 0 20px" }}>
                <div className="col-6" style={{ display: 'flex', alignItems: 'center', color: 'gray', cursor: 'pointer' }} onClick={handleGoBack}>
                    <IoIosArrowBack /><div style={{ marginLeft: 10 }} >동영상 목록보기</div>
                </div>
                <div className="col-6" style={{ padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* <Nav className='videoNav' variant="tabs">
                        <Nav.Item><Nav.Link className={navMode == 'sub' ? "active" : ''} onClick={() => setNavMode('sub')}>자막</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className={navMode == 'chap' ? "active" : ''} onClick={() => setNavMode('chap')}>챕터</Nav.Link></Nav.Item>
                    </Nav> */}

                    <a style={{ color: 'gray', cursor: 'pointer' }} onClick={handleAIVodBtn}>AI VOD 플레이어</a>
                    <div>
                        {process.env.NODE_ENV === 'development' &&
                            <Button id="subEditBtn" variant='warning' onClick={handleEditBtn}>자막 편집기</Button>}
                    </div>

                </div>
            </Row>

            <Row>
                <div className='col-6' style={{ "padding": 0 }}>
                    <Player wavesurfer={wavesurfer} setDurationTime={setDurationTime} setPlayTime={setPlayTime} timelineTime={timelineTime} uuid={videoInfo.uuid} selectedLang={selectedLang} />
                </div>
                <div className='col-6' style={{ "padding": "70px 0 0 0", minHeight: 550 }} ref={scrollRef}>
                    {
                        langList.length > 0 ?
                            <>
                                <div className="language-selector">
                                    <div className="dropDownDiv">
                                        <label>{navMode === 'sub' ? '자막 언어: ' : '챕터 언어: '}</label>
                                        <Dropdown onSelect={handleLanguageChange}>
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                <div>{selectedLang.koLabel}</div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {langList.map(el => (
                                                    <Dropdown.Item key={el.lang} eventKey={el.lang}>
                                                        {el.koLabel}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className='listDiv'>
                                    {navMode == 'sub' && parsedSub.length > 0 ?
                                        parsedSub.map((item, idx) => (
                                            <SubList key={item.id} idx={idx} item={item} playTime={playTime} setScrollHeight={setScrollHeight} setSelectedIndices={setSelectedIndices} selectedIndices={selectedIndices} />
                                        ))
                                        :
                                        <div style={{ width: "100%", height: 500, paddingLeft: 50 }}></div>
                                    }
                                    {/* {navMode == 'chap' &&
                                        <ChapList />
                                    } */}
                                </div>
                            </>
                            :
                            noVtt ?
                                <div style={{ width: "100%", height: 500, textAlign: "center", paddingTop: 150, paddingLeft: 50 }}>
                                    <div style={{ marginBottom: 20 }}>콘텐츠가 없습니다.</div>
                                    {process.env.NODE_ENV === 'development' &&
                                        <div>
                                            <Button id="subEditBtn" onClick={handleEditBtn}>자막 편집기</Button>
                                        </div>
                                    }
                                </div>
                                :
                                <div style={{ width: "100%", height: 500, paddingLeft: 50 }}><LoadingComponent /></div>
                    }

                </div>
            </Row>

            <Row>
                <div className='col-12' style={{ padding: 0 }}>
                    <WaveSurferTimeline wavesurfer={wavesurfer} setTimelineTime={setTimelineTime} durationTime={durationTime} uuid={videoInfo.uuid} parsedSub={parsedSub} />
                </div>
            </Row>

        </div>
    );
}
export default VideoDetail;