import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, InputGroup, Button } from 'react-bootstrap';

import { setSubSelectMode } from '../../modules/video';

import QuestionInput from '../_common/QuestionInput';

function VideoQuestion({ question, questions, setQuestions, idx }) {

    const [isSelected, setIsSelected] = useState(false);

    const subSelectMode = useSelector(state => state.video.subSelectMode);

    const dispatch = useDispatch();

    const handleQuestionType = (e) => {
        var newQuestion = questions;
        newQuestion[idx].questionType = e.target.value;
        setQuestions([...newQuestion]);
    };

    const handleQuestionCount = (e) => {
        var newQuestion = questions;
        newQuestion[idx].questionCount = e.target.value;
        setQuestions([...newQuestion]);
    };

    const handleMultiChoiceCount = (e) => {
        var newQuestion = questions;
        newQuestion[idx].multiChoiceCount = e.target.value;
        setQuestions([...newQuestion]);
    };

    function deleteQuestion() {
        var tmpQuestions = [...questions];
        tmpQuestions.splice(idx, 1)
        setQuestions(tmpQuestions);
    }

    useEffect(() => {
        if (isSelected) {
            dispatch(setSubSelectMode(true));
        }
        else {
            dispatch(setSubSelectMode(false));
        }
    }, [isSelected])

    const handleSubtitleClick = () => {
        if (subSelectMode) {
            alert("자막 선택을 완료 후 다음 자막을 선택해주세요");
        } else {
            setIsSelected(true);
        }
    };

    return (
        <div className='group-opts-question'>
            <div className='row' style={{ 'marginBottom': '5px' }}>
                <div className="input-group col-sm-12 section-data">
                    <InputGroup className="mb-6 data-value data-base-question">
                        <div className="input-group">
                            <div className="input-group-prepend" style={{ width: '100%' }}>
                                <label className="input-group-text custom-label" htmlFor="videoQuestionData">data</label>
                                <div id="videoQuestionData" placeholder="자막 추가" style={{ backgroundColor: isSelected ? '#e9ecef' : 'initial' }} >
                                    {
                                        isSelected ?
                                            <QuestionInput idx={idx} questions={questions} setQuestions={setQuestions} setIsSelected={setIsSelected} />
                                            :
                                            question.questionData ?
                                                <Row style={{ width: '100%' }} >
                                                    <div className="col-1 listItemCol1">
                                                        {question.questionData.indexStart}
                                                        ~
                                                        {question.questionData.indexEnd}
                                                    </div>
                                                    <div className="col-2 listItemCol3">
                                                        <div id='start-time'>
                                                            {question.questionData.timeStart}
                                                        </div>
                                                        <div id='end-time'>
                                                            {question.questionData.timeEnd}
                                                        </div>
                                                    </div>
                                                    <div className='col-9 listItemCol8'>
                                                        <div style={{ lineHeight: '1.6' }}>
                                                            {question.questionData.text}
                                                        </div>
                                                    </div>
                                                </Row>
                                                :
                                                <div onClick={handleSubtitleClick} style={{ cursor: 'pointer', color: 'blue' }}>자막 선택</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </InputGroup>
                </div>
            </div>
            <div className='row'>
                <div className="col-sm-2">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <label className="input-group-text custom-label" htmlFor="contentTypeSelect">Type</label>
                        </div>
                        <select className="select-question-type custom-select" id="contentTypeSelect" value={questions[idx].questionType} onChange={(e) => handleQuestionType(e)}>
                            <option value="multichoice">선다형</option>
                            <option value="truefalse">참/거짓</option>
                            <option value="shortanswer">주관식 단답형</option>
                            <option value="essay">서술형</option>
                        </select>
                    </div>
                </div>
                <div className="input-group col-sm-2">
                    <div className="input-group-prepend">
                        <label className="input-group-text custom-numbers-label" htmlFor="selectBox">생성 문항 개수</label>
                    </div>
                    <select className="custom-select custom-numbers-select" id="selectBox" value={questions[idx].questionCount} onChange={(e) => handleQuestionCount(e)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>

                <div className={`input-group col-sm-3 box-multichoice-count ${questions[idx].questionType === 'multichoice' ? '' : 'd-none'}`}>
                    <div className="input-group-prepend">
                        <label className="input-group-text custom-choice-label" htmlFor="selectBox">선다형 답안 개수</label>
                    </div>
                    <select className="select-multichoice-option-count custom-select" id="selectBox" value={questions[idx].multiChoiceCount} onChange={(e) => handleMultiChoiceCount(e)} style={{ "maxWidth": "25%" }}>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
                <div className="col-sm-4 ml-auto" ></div>
                <div className="col-sm-1 ml-auto" >
                    <Button variant="warning" onClick={deleteQuestion} style={{ width: "100%" }} >-</Button>
                </div>
            </div>
        </div>
    );
}

export default VideoQuestion;